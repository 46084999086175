import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";

import "./App.css";

import NavBar from "./Partials/Sections/NavBar/NavBar";
import SideBar from "./Partials/Sections/SideBar/SideBar";

import Login from "./Screens/AuthScreens/Login/Login";

import ItemGroups from "./Screens/ItemGroups/ItemGroups";
// import Collections from './Screens/ItemGroups/CollectionScreens/Collections';
import Collections from "./Screens/ItemGroups/CollectionScreens/Collections/Collections";
// import NavCollections from './Screens/ItemGroups/CollectionScreens/NavCollections';
import NavCollections from "./Screens/ItemGroups/CollectionScreens/NavCollections/NavCollections";
// import Categories from './Screens/ItemGroups/CategoryScreens/Categories';
import Categories from "./Screens/ItemGroups/CategoryScreens/Categories/Categories";

// import FeaturedCategories from './Screens/ItemGroups/CategoryScreens/FeaturedCategories';
import GeneralSettings from "./Screens/GeneralSettingsScreens/GeneralSettings";
import SocialLinks from "./Screens/GeneralSettingsScreens/SocialLinks/SocialLinks";
import Testimonials from "./Screens/GeneralSettingsScreens/Testimonials/Testimonials";
import FeaturedCategories from "./Screens/ItemGroups/CategoryScreens/FeaturedCategories/FeaturedCategories";
// import SubCategories from './Screens/ItemGroups/CategoryScreens/SubCategories';
import SubCategories from "./Screens/ItemGroups/CategoryScreens/SubCategories/SubCategories";

import AddressScreens from "./Screens/AddressScreens/AddressScreens";
import ItemCategories from "./Screens/ItemGroups/ClassifiedItemsScreens/ItemCategories";
import ItemCollections from "./Screens/ItemGroups/ClassifiedItemsScreens/ItemCollections";
import ItemSubcategories from "./Screens/ItemGroups/ClassifiedItemsScreens/ItemSubcategories";
import FeaturedItems from "./Screens/ItemScreens/FeaturedItems/FeaturedItems";
import Items from "./Screens/ItemScreens/Items/Items";
// import Areas from './Screens/AddressScreens/Areas';
import Areas from "./Screens/AddressScreens/Areas/Areas";
import Districts from "./Screens/AddressScreens/Districts";
import Divisions from "./Screens/AddressScreens/Divisions";
import InviteEmployees from "./Screens/AuthScreens/InviteEmployees/InviteEmployees";
import RecoverPassOne from "./Screens/AuthScreens/RecoverPassOne";
import Register from "./Screens/AuthScreens/Register/Register";
import Dashboard from "./Screens/Dashboard/Dashboard";
import CategoryScreens from "./Screens/ItemGroups/CategoryScreens/CategoryScreens";
import CollectionScreens from "./Screens/ItemGroups/CollectionScreens/CollectionScreens";
import ItemAssets from "./Screens/ItemScreens/ItemAssets/ItemAssets";
import Wishlist from "./Screens/ItemScreens/Wishlist/Wishlist";
import OrderItemsFromOrders from "./Screens/OrderScreens/OrderItemsFromOrders";
import OrderScreens from "./Screens/OrderScreens/OrderScreens";
import OrdersFromStatus from "./Screens/OrderScreens/OrdersFromStatus/OrdersFromStatus";
import Employees from "./Screens/UsersScreens/Employees/Employees";
import UsersScreens from "./Screens/UsersScreens/UsersScreens";
// import RecoverPassTwo from './Screens/AuthScreens/RecoverPassTwo';
import axios from "axios";
import RecoverPassThree from "./Screens/AuthScreens/RecoverPassThree";
import ItemAssetsFromItems from "./Screens/ItemScreens/ItemAssets/ItemAssetsFromItems";
import MyProfile from "./Screens/MyProfile/MyProfile";
import ChangePassword from "./Screens/MyProfile/ProfileInfo/ChangePassword/ChangePassword";
// import EditProfileInfo from './Screens/MyProfile/ProfileInfo/EditProfileInfo/EditProfileInfo';
import { useContext } from "react";
import AppContext from "../AppContext/AppContext";
import AboutContent from "./Screens/About/AboutContent";
import HistoryOfSPSB from "./Screens/About/HistoryOfSPSB/HistoryOfSPSB";
import PresidentPossession from "./Screens/About/PresidentPossession/PresidentPossession";
import QNASection from "./Screens/About/QNASection/QNASection";
import SecretaryPossessions from "./Screens/About/SecretaryPossessions/SecretaryPossessions";
import Comments from "./Screens/Blogs/Comments/Comments";
import BlogPost from "./Screens/Blogs/Post/BlogPost";
import Contact from "./Screens/Contact/Contact";
import ContactMessage from "./Screens/Contact/ContactMessage/ContactMessage";
import EventsContent from "./Screens/Events/EventsContent/EventsContent";
import Hotel from "./Screens/Events/Hotel/Hotel";
import Visit from "./Screens/Events/Visit/Visit";
import GalleryContent from "./Screens/Gallery/GalleryContent/GalleryContent";
import GalleryImage from "./Screens/Gallery/GalleryContent/GalleryImage";
import Benefits from "./Screens/Home/Benefits/Benefits";
import ConferenceAndMembership from "./Screens/Home/ConferenceAndMembership/ConferenceAndMembership";
import GovernmentInitiative from "./Screens/Home/GovernmentInitiative/GovernmentInitiative";
import HomeContent from "./Screens/Home/HomeContent";
import RevenueGrowth from "./Screens/Home/RevenueGrowth/RevenueGrowth";
import TeamSection from "./Screens/Home/TeamSeaction/TeamSection";
import Ticket from "./Screens/Events/Ticket/Ticket";
import APBC_EventsContent from "./Screens/APBC_Events/APBC_EventsContent/APBC_EventsContent";
import APBC_GalleryImage from "./Screens/APBC_Events/APBC_EventsContent/APBC_EventsGalleryImages/APBC_EventsGalleryImages";
import APBCTicket from "./Screens/APBC_Events/APBCTicket/APBCTicket";
import UpdateYoutubeLink from "./Screens/Home/YoutubeLink/YoutubeLink";
import Hero from "./Screens/Home/Hero/Hero";
import EventVisuals from "./Screens/Events/EventsContent/EventVisuals";
import TopSection from "./Screens/About/AboutForm/TopSection";
import OurMission from "./Screens/About/AboutForm/OurMission";
import AllEvents from "./Screens/AllEvents/AllEvents";
import FreeEvents from "./Screens/AllEvents/events/FreeEvents/FreeEvents";
import PremiumEvents from "./Screens/AllEvents/events/premiumEvents/PremiumEvents";
import EventTickets from "./Screens/AllEvents/events/premiumEvents/page/tickets";
import CustomerScreens from "./Screens/UsersScreens/Customers/CustomerScreens";
import Customers from "./Screens/UsersScreens/Customers/Members/Customers";
import AddressesFromCustomer from "./Screens/UsersScreens/Customers/Members/AddressesFromCustomer";
import OrdersFromCustomer from "./Screens/UsersScreens/Customers/Members/OrdersFromCustomer/OrdersFromCustomer";
import Payment from "./Screens/UsersScreens/Customers/Payment/Payment";
import AppSettings from "./Screens/GeneralSettingsScreens/AppSettings/AppSettings";
import Journal from "./Screens/JournalScreen/Journal";

function App() {
  const { logout, employee } = useContext(AppContext);

  axios.interceptors.response.use(
    (res) => {
      return res;
    },
    (err) => {
      if (err.response.status === 401) {
        logout();
      }
      return Promise.reject(err);
    }
  );

  // const { employee } = useSelector(state => state.auth)

  return (
    <>
      <BrowserRouter>
        <NavBar />

        <main>
          {employee && <SideBar />}

          <div className="screen-container">
            <Routes>
              <Route path="/login" element={<Login />} />
              <Route
                path="/register/:token"
                element={!employee ? <Register /> : <Navigate to={"/"} />}
              />

              <Route
                path={"/"}
                element={employee ? <Dashboard /> : <Navigate to={"/login"} />}
              />
              <Route path="/recover-pass-one" element={<RecoverPassOne />} />
              <Route
                path="/resetEmployeePassword/:token"
                element={<RecoverPassThree />}
              />
              <Route
                path="/profile"
                element={employee ? <MyProfile /> : <Navigate to="/login" />}
              />
              <Route
                path="/change-password"
                element={
                  employee ? <ChangePassword /> : <Navigate to="/login" />
                }
              />
              <Route
                path="/appSettings"
                element={employee ? <AppSettings /> : <Navigate to="/login" />}
              />

              <Route
                path={"/user_screens"}
                element={
                  employee ? <UsersScreens /> : <Navigate to={"/login"} />
                }
              />
              <Route
                path={"/customerScreens"}
                element={
                  employee && employee?.level === "owner" ? (
                    <CustomerScreens />
                  ) : (
                    <Navigate to={"/"} />
                  )
                }
              />
              <Route
                path={"/customers"}
                element={
                  employee && employee?.level === "owner" ? (
                    <Customers />
                  ) : (
                    <Navigate to={"/"} />
                  )
                }
              />
              <Route
                path={"/employees"}
                element={employee ? <Employees /> : <Navigate to={"/login"} />}
              />
              <Route
                path={"/employee-invites"}
                element={
                  employee ? <InviteEmployees /> : <Navigate to={"/login"} />
                }
              />

              <Route
                path={"/addresses_from_customer/:customerID"}
                element={
                  employee ? (
                    <AddressesFromCustomer />
                  ) : (
                    <Navigate to={"/login"} />
                  )
                }
              />
              <Route
                path={"/orders_from_customer/:customerID"}
                element={
                  employee ? <OrdersFromCustomer /> : <Navigate to={"/login"} />
                }
              />

              <Route
                path={"/orderScreens"}
                element={
                  employee ? <OrderScreens /> : <Navigate to={"/login"} />
                }
              />
              <Route
                path={"/ordersFromStatus/:status"}
                element={
                  employee ? <OrdersFromStatus /> : <Navigate to={"/login"} />
                }
              />

              <Route
                path={"/order_items_from_order/:orderID"}
                element={
                  employee ? (
                    <OrderItemsFromOrders />
                  ) : (
                    <Navigate to={"/login"} />
                  )
                }
              />

              {/* home page sections routes */}

              <Route
                path="/home"
                element={
                  employee ? <HomeContent /> : <Navigate to={"/login"} />
                }
              />

              <Route
                path="/hero"
                element={employee ? <Hero /> : <Navigate to={"/login"} />}
              />
              <Route
                path="/team"
                element={
                  employee ? <TeamSection /> : <Navigate to={"/login"} />
                }
              />
              <Route
                path="/revenueGrowth"
                element={
                  employee ? <RevenueGrowth /> : <Navigate to={"/login"} />
                }
              />

              <Route
                path="/conferenceAndMembership"
                element={
                  employee ? (
                    <ConferenceAndMembership />
                  ) : (
                    <Navigate to={"/login"} />
                  )
                }
              />

              <Route
                path="/benefits"
                element={employee ? <Benefits /> : <Navigate to={"/login"} />}
              />

              <Route
                path="/youtubeLink"
                element={
                  employee ? <UpdateYoutubeLink /> : <Navigate to={"/login"} />
                }
              />

              <Route
                path="/paymentDetails"
                element={employee ? <Payment /> : <Navigate to={"/login"} />}
              />
              <Route
                path="/governmentInitiative"
                element={
                  employee ? (
                    <GovernmentInitiative />
                  ) : (
                    <Navigate to={"/login"} />
                  )
                }
              />

              {/* about sections routes */}

              <Route
                path="/about"
                element={
                  employee ? <AboutContent /> : <Navigate to={"/login"} />
                }
              />
              <Route
                path="/about/top-section"
                element={employee ? <TopSection /> : <Navigate to={"/login"} />}
              />
              <Route
                path="/about/our-mission"
                element={employee ? <OurMission /> : <Navigate to={"/login"} />}
              />

              <Route
                path="/historyOfSPSB"
                element={
                  employee ? <HistoryOfSPSB /> : <Navigate to={"/login"} />
                }
              />

              <Route
                path="/secretaryPossessions"
                element={
                  employee ? (
                    <SecretaryPossessions />
                  ) : (
                    <Navigate to={"/login"} />
                  )
                }
              />

              <Route
                path="/presidentPossession"
                element={
                  employee ? (
                    <PresidentPossession />
                  ) : (
                    <Navigate to={"/login"} />
                  )
                }
              />

              <Route
                path="/QNASection"
                element={employee ? <QNASection /> : <Navigate to={"/login"} />}
              />

              {/* Gallery page routes */}

              <Route
                path="/gallery_content"
                element={
                  employee ? <GalleryContent /> : <Navigate to={"/login"} />
                }
              />
              {/* Gallery Image routes */}

              <Route
                path="/gallery_content/:galleryId"
                element={
                  employee ? <GalleryImage /> : <Navigate to={"/login"} />
                }
              />
              {/* Events page routes */}

              <Route
                path="/allEvents"
                element={employee ? <AllEvents /> : <Navigate to={"/login"} />}
              />
              <Route
                path="/allEvents/premiumEvents"
                element={
                  employee ? <PremiumEvents /> : <Navigate to={"/login"} />
                }
              />
              <Route
                path="/allEvents/FreeEvents"
                element={employee ? <FreeEvents /> : <Navigate to={"/login"} />}
              />

              <Route
                path="/allEvents/freeEvents/visuals/:eventId"
                element={
                  employee ? <EventVisuals /> : <Navigate to={"/login"} />
                }
              />

              <Route
                path="/allEvents/ticket/:eventId"
                element={
                  employee ? <EventTickets /> : <Navigate to={"/login"} />
                }
              />

              <Route
                path="/APBC_Events"
                element={
                  employee ? <APBC_EventsContent /> : <Navigate to={"/login"} />
                }
              />
              <Route
                path="/APBC_Events/gallery/:galleryId"
                element={
                  employee ? <APBC_GalleryImage /> : <Navigate to={"/login"} />
                }
              />
              <Route
                path="/APBC_Events/ticket/:eventId"
                element={employee ? <APBCTicket /> : <Navigate to={"/login"} />}
              />

              <Route
                path="/events"
                element={
                  employee ? <EventsContent /> : <Navigate to={"/login"} />
                }
              />
              <Route
                path="/events/:eventId"
                element={
                  employee ? <EventVisuals /> : <Navigate to={"/login"} />
                }
              />

              <Route
                path="/hotel_content/:eventId"
                element={employee ? <Hotel /> : <Navigate to={"/login"} />}
              />

              {/* Visit  */}
              <Route
                path="/visit_content/:visitId"
                element={employee ? <Visit /> : <Navigate to={"/login"} />}
              />
              {/* Ticket  */}
              <Route
                path="/ticket_content/:eventId"
                element={employee ? <Ticket /> : <Navigate to={"/login"} />}
              />

              {/* Journal */}
              <Route
                path="/journal"
                element={employee ? <Journal /> : <Navigate to={"/login"} />}
              />

              {/* Blogs routes */}
              <Route
                path="/blogs"
                element={employee ? <BlogPost /> : <Navigate to={"/login"} />}
              />

              <Route
                path="/blogs/:id"
                element={employee ? <Comments /> : <Navigate to={"/login"} />}
              />
              {/* Contact routes */}

              <Route
                path="/contact"
                element={employee ? <Contact /> : <Navigate to={"/login"} />}
              />

              <Route
                path="/contact_message"
                element={
                  employee ? <ContactMessage /> : <Navigate to={"/login"} />
                }
              />

              <Route
                path="/items"
                element={employee ? <Items /> : <Navigate to={"/login"} />}
              />
              <Route
                path="/item_assets_from_items/:itemID"
                element={
                  employee ? (
                    <ItemAssetsFromItems />
                  ) : (
                    <Navigate to={"/login"} />
                  )
                }
              />
              <Route
                path="/item_assets"
                element={employee ? <ItemAssets /> : <Navigate to={"/login"} />}
              />
              <Route
                path="/featured_items"
                element={
                  employee ? <FeaturedItems /> : <Navigate to={"/login"} />
                }
              />
              <Route
                path="/wishlists"
                element={employee ? <Wishlist /> : <Navigate to={"/login"} />}
              />

              <Route
                path="/item_collections/:parentID"
                element={
                  employee ? <ItemCollections /> : <Navigate to={"/login"} />
                }
              />
              <Route
                path="/item_categories/:parentID"
                element={
                  employee ? <ItemCategories /> : <Navigate to={"/login"} />
                }
              />
              <Route
                path="/item_subcategories/:parentID"
                element={
                  employee ? <ItemSubcategories /> : <Navigate to={"/login"} />
                }
              />

              <Route
                path="/item_groups"
                element={employee ? <ItemGroups /> : <Navigate to={"/login"} />}
              />

              <Route
                path="/collection_screens"
                element={
                  employee ? <CollectionScreens /> : <Navigate to={"/login"} />
                }
              />
              <Route
                path="/collections"
                element={
                  employee ? <Collections /> : <Navigate to={"/login"} />
                }
              />
              <Route
                path="/nav_collections"
                element={
                  employee ? <NavCollections /> : <Navigate to={"/login"} />
                }
              />

              <Route
                path="/category_screens"
                element={
                  employee ? <CategoryScreens /> : <Navigate to={"/login"} />
                }
              />
              <Route
                path="/categories"
                element={employee ? <Categories /> : <Navigate to={"/login"} />}
              />
              <Route
                path="/featured_categories"
                element={
                  employee ? <FeaturedCategories /> : <Navigate to={"/login"} />
                }
              />
              <Route
                path="/subcategories/:categoryId"
                element={
                  employee ? <SubCategories /> : <Navigate to={"/login"} />
                }
              />

              {/* <Route path='/size_screens' element={employee ? <SizeScreens /> : <Navigate to={'/login'} />} />
              <Route path='/size_groups' element={employee ? <SizeGroups /> : <Navigate to={'/login'} />} />
              <Route path='/sizesFromSizeGroup/:parentID' element={employee ? <SizesFromSizeGroup /> : <Navigate to={'/login'} />} />
              <Route path='/sizes' element={employee ? <Sizes /> : <Navigate to={'/login'} />} />
              <Route path='/item_sizes' element={employee ? <ItemSizes /> : <Navigate to={'/login'} />} />
              <Route path='/itemSizesFromItem/:parentID' element={employee ? <ItemSizesFromItem /> : <Navigate to={'/login'} />} /> */}

              <Route
                path="/address_screens"
                element={
                  employee ? <AddressScreens /> : <Navigate to={"/login"} />
                }
              />
              <Route
                path="/areas/:parentID"
                element={employee ? <Areas /> : <Navigate to={"/login"} />}
              />
              <Route
                path="/districts/:parentID"
                element={employee ? <Districts /> : <Navigate to={"/login"} />}
              />
              <Route
                path="/divisions"
                element={employee ? <Divisions /> : <Navigate to={"/login"} />}
              />

              <Route
                path="/general_settings"
                element={
                  employee ? <GeneralSettings /> : <Navigate to={"/login"} />
                }
              />
              <Route
                path="/social_links"
                element={
                  employee ? <SocialLinks /> : <Navigate to={"/login"} />
                }
              />

              <Route
                path="/testimonials"
                element={
                  employee ? <Testimonials /> : <Navigate to={"/login"} />
                }
              />

              <Route
                path="/app_settings"
                element={
                  employee ? <AppSettings /> : <Navigate to={"/login"} />
                }
              />
            </Routes>
          </div>
        </main>
      </BrowserRouter>
    </>
  );
}

export default App;
