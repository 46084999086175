import { useEffect, useState } from "react";
import useAxiosInstance from "../../../../Utilities/Hooks/useAxiosInstance";
import CRUDTable from "../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTable";
import CRUDTableHeader from "../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableHeader/CRUDTableHeader";
import CRUDth from "../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableHeader/CRUDth/CRUDth";
import CRUDTableRow from "../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableRow/CRUDTableRow";
import ShortTextCell from "../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableCells/ShortTextCell/ShortTextCell";
import ImageCell from "../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableCells/ImageCell/ImageCell";
import ViewButton from "../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDButtons/ViewButton/ViewButton";
import CRUDButton from "../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDButtons/CRUDButton";
import Modal from "../../../Partials/Elements/Modal/Modal";
import { APP_SETTINGS_API } from "../../../../Utilities/APIs/APIs";
import { FiPenTool } from "react-icons/fi";
import DeleteButton from "../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDButtons/DeleteButton/DeleteButton";
import ViewAppSetting from "./ViewAppSetting";
import UpdateAppSetting from "./UpdateAppSetting";
import DeleteItem from "../../../Partials/Layouts/DeleteItem/DeleteItem";
import CreateButton from "../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDDashboardBoilerplate/CRUDHeader/CreateButton/CreateButton";
import CreateAppSetting from "./components/CreateAppSetting";


function AppSettings() {
  const [content, setContent] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [targetID, setTargetID] = useState(null);
  const [showViewSection, setShowViewSection] = useState(false);
  const [showCreateForm, setShowCreateForm] = useState(false);
  const [showUpdateForm, setShowUpdateForm] = useState(false);

  const [showDeleteSection, setShowDeleteSection] = useState(false);
  const [toggleFetch, setToggleFetch] = useState(false);

  const axiosInstance = useAxiosInstance();

  useEffect(() => {
    async function fetchAndSetContent() {
      const { data } = await axiosInstance.get(APP_SETTINGS_API);
      setContent(data);
    }
    fetchAndSetContent();
  }, [toggleFetch, axiosInstance, showModal]);

  function handleClose() {
    setShowModal(false);
    setShowCreateForm(false);
    setShowViewSection(false);
    setShowUpdateForm(false);
    setShowDeleteSection(false);
  }

  function triggerFetch() {
    setToggleFetch((prevState) => !prevState);
  }

  return (
    <>
      <div className="crud_holder">
        <div className="body_header">
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              gap: "20px",
              width: "100%",
            }}
          >
            <h1 className="heading">{`App Settings (${content?.length} in total)`}</h1>
            {
              content?.length < 1 && <CreateButton
                screenTopicSingular={"Gallery Image"}
                setShowCreateForm={setShowCreateForm}
                setShowModal={setShowModal}
              />
            }
          </div>
        </div>
        <CRUDTable>
          <CRUDTableHeader>
            <CRUDth th="Name" />
            <CRUDth th="Image" />
            <CRUDth th="Actions" />
          </CRUDTableHeader>
          <tbody>
            {content?.map((item) => (
              <CRUDTableRow key={item._id}>
                <ShortTextCell text={item?.title} />
                <ImageCell
                  imgSrc={process.env.REACT_APP_SPACES_URL + item.image}
                  alt={"team member"}
                />

                <td className="action_button_cell">
                  <ViewButton
                    setShowModal={setShowModal}
                    setShowViewSection={setShowViewSection}
                    targetID={item._id}
                    setTargetID={setTargetID}
                  />

                  {/* {!item?.isApproved && ( */}
                  <CRUDButton
                    handleClick={() => {
                      setShowModal(true);
                      setShowUpdateForm(true)
                      setTargetID(item._id);
                    }}
                  >
                    <FiPenTool />
                  </CRUDButton>
                  <DeleteButton
                    setShowModal={setShowModal}
                    setShowDeleteSection={setShowDeleteSection}
                    targetID={item._id}
                    setTargetID={setTargetID}
                  />
                </td>
              </CRUDTableRow>
            ))}
          </tbody>
        </CRUDTable>
      </div>

      {showModal && (
        <Modal
          view
          handleClose={handleClose}
          modalHeading={
            (showDeleteSection && `Delete App Setting`)
          }
        >
          {showDeleteSection && (
            <DeleteItem
              api={APP_SETTINGS_API + "delete/"}
              targetID={targetID}
              setShowModal={setShowModal}
              setShowDeleteSection={setShowDeleteSection}
              hasName
              triggerFetch={triggerFetch}
            />
          )}
        </Modal>
      )}

      {showModal && showViewSection && (
        <Modal
          view
          handleClose={handleClose}
          modalHeading={showViewSection && `View App Setting`}
        >
          <ViewAppSetting
            targetID={targetID}
          />
        </Modal>
      )}

      {showModal && showCreateForm && (
        <Modal
          view
          handleClose={handleClose}
          modalHeading={showViewSection && `View App Settting`}
        >
          <CreateAppSetting
            setShowCreateForm={setShowCreateForm}
            setShowModal={setShowModal}
            triggerFetch={triggerFetch}
          />
        </Modal>
      )}

      {showModal && showUpdateForm && (
        <Modal
          view
          handleClose={handleClose}
          modalHeading={
            (showUpdateForm && `Update App Setting`)

          }
        >
          <UpdateAppSetting
            targetID={targetID}
            setShowUpdateForm={setShowUpdateForm}
            setShowModal={setShowModal}
            triggerFetch={triggerFetch}
          />
        </Modal>
      )}
    </>
  );
}

export default AppSettings;
