import { useContext, useEffect, useState } from "react";
import { IoTicketOutline } from "react-icons/io5";
import { RiHotelBedLine, RiImage2Line, RiPlayCircleFill } from "react-icons/ri";
import { useNavigate } from "react-router-dom";
import AppContext from "../../../../AppContext/AppContext";
import { ALL_EVENTS_API, EVENTS_API } from "../../../../Utilities/APIs/APIs";
import useAxiosInstance from "../../../../Utilities/Hooks/useAxiosInstance";
import Modal from "../../../Partials/Elements/Modal/Modal";
import CreateButton from "../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDDashboardBoilerplate/CRUDHeader/CreateButton/CreateButton";
import CRUDButton from "../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDButtons/CRUDButton";
import DeleteButton from "../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDButtons/DeleteButton/DeleteButton";
import EditButton from "../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDButtons/EditButton/EditButton";
import ViewButton from "../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDButtons/ViewButton/ViewButton";
import CRUDTable from "../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTable";
import ImageCell from "../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableCells/ImageCell/ImageCell";
import ShortTextCell from "../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableCells/ShortTextCell/ShortTextCell";
import CRUDTableHeader from "../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableHeader/CRUDTableHeader";
import CRUDth from "../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableHeader/CRUDth/CRUDth";
import CRUDTableRow from "../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableRow/CRUDTableRow";
import DeleteItem from "../../../Partials/Layouts/DeleteItem/DeleteItem";
import CreateEvent from "./CreateEvent";
import UpdateEvent from "./UpdateEvent";
import ViewEvent from "./ViewEvent";
import ApproveItem from "../Ticket/AppoveItem";
import ArchiveButton from "../../../Partials/Layouts/CRUDs/ArchiveButton/ArchiveButton";
import ArchiveItem from "../../../Partials/Layouts/ArchiveItem/ArchiveItem";
import FilterSelect from "../../../Partials/Layouts/Forms/FilterSelect/FilterSelect";
// import"../../"

function EventsContent() {
  const [eventItems, setEventItems] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [targetID, setTargetID] = useState(null);
  const [showApprove, setShowApprove] = useState(false);
  const [showViewSection, setShowViewSection] = useState(false);
  const [showCreateForm, setShowCreateForm] = useState(false);
  const [showUpdateForm, setShowUpdateForm] = useState(false);
  const [showDeleteSection, setShowDeleteSection] = useState(false);
  const [toggleFetch, setToggleFetch] = useState(false);
  const [archive, setArchive] = useState(false);
  const [filter, setFilter] = useState("active");

  const [showArchiveSection, setShowArchiveSection] = useState(false);
  const { employee } = useContext(AppContext);

  const axiosInstance = useAxiosInstance();
  const navigate = useNavigate();

  useEffect(() => {
    async function fetchAndSetFeaturedItems() {
      const { data } = await axiosInstance.get(
        ALL_EVENTS_API + "all" + `?filter=${filter}`
      );
      setEventItems(data);
    }
    fetchAndSetFeaturedItems();
  }, [toggleFetch, axiosInstance, showModal, filter]);

  function handleClose() {
    setShowModal(false);
    setShowCreateForm(false);
    setShowViewSection(false);
    setShowUpdateForm(false);
    setShowDeleteSection(false);
    setShowApprove(false);
    setShowArchiveSection(false);
  }

  function triggerFetch() {
    setToggleFetch((prevState) => !prevState);
  }

  return (
    <>
      <div className="crud_holder">
        <div className="body_header">
          <div style={{ display: "flex", alignItems: "center", gap: "20px" }}>
            <h1 className="heading">{`Event Items (${eventItems?.length} in total)`}</h1>

            <FilterSelect filter={filter} setFilter={setFilter} />
          </div>
          <CreateButton
            screenTopicSingular="Event"
            setShowModal={setShowModal}
            setShowCreateForm={setShowCreateForm}
          />
        </div>
        <CRUDTable>
          <CRUDTableHeader>
            <CRUDth th="No" />
            <CRUDth th="Title" />
            {/* <CRUDth th="Short Description" />
            <CRUDth th="Long Description" /> */}
            <CRUDth th="Image" />
            <CRUDth th="Action" />
          </CRUDTableHeader>
          <tbody>
            {eventItems?.map((eventItem, index) => (
              <CRUDTableRow key={eventItem._id}>
                <ShortTextCell text={index + 1} />
                <ShortTextCell text={eventItem?.title} />
                {/* <ShortTextCell text={eventItem?.shortDescription} />
                <ShortTextCell text={eventItem?.longDescription} /> */}
                <ImageCell
                  imgSrc={process.env.REACT_APP_SPACES_URL + eventItem?.image}
                  alt={"team member"}
                />

                <td className="action_button_cell">
                  <ViewButton
                    setShowModal={setShowModal}
                    setShowViewSection={setShowViewSection}
                    targetID={eventItem._id}
                    setTargetID={setTargetID}
                  />
                  <EditButton
                    setShowModal={setShowModal}
                    setShowUpdateForm={setShowUpdateForm}
                    targetID={eventItem._id}
                    setTargetID={setTargetID}
                  />

                  <CRUDButton
                    handleClick={() => {
                      navigate(`/events/${eventItem._id}`);
                    }}
                  >
                    <RiImage2Line />
                  </CRUDButton>

                  <ArchiveButton
                    setShowModal={setShowModal}
                    setShowDeleteSection={setShowArchiveSection}
                    targetID={eventItem._id}
                    isArchive={eventItem?.isActive}
                    setTargetID={setTargetID}
                    setArchive={setArchive}
                  />
                  <CRUDButton
                    handleClick={() => {
                      navigate(`/hotel_content/${eventItem._id}`);
                    }}
                  >
                    <RiHotelBedLine />
                  </CRUDButton>
                  <CRUDButton
                    handleClick={() => {
                      navigate(`/visit_content/${eventItem._id}`);
                    }}
                  >
                    <RiPlayCircleFill />
                  </CRUDButton>
                  <CRUDButton
                    handleClick={() => {
                      navigate(`/ticket_content/${eventItem._id}`);
                      setShowModal(true);
                      setShowApprove(true);
                      setTargetID(eventItem._id);
                    }}
                  >
                    <IoTicketOutline />
                  </CRUDButton>

                  <DeleteButton
                    setShowModal={setShowModal}
                    setShowDeleteSection={setShowDeleteSection}
                    targetID={eventItem._id}
                    setTargetID={setTargetID}
                  />
                </td>
              </CRUDTableRow>
            ))}
          </tbody>
        </CRUDTable>
      </div>
      {showModal && (
        <Modal
          view
          handleClose={handleClose}
          modalHeading={
            (showCreateForm && `Create Event Item`) ||
            (showUpdateForm && `Update Event Item`) ||
            (showDeleteSection && `Delete Event Item`) ||
            (showArchiveSection && `Archive Event Item`)
          }
        >
          {showCreateForm && (
            <CreateEvent
              employee={employee}
              setShowCreateForm={setShowCreateForm}
              setShowModal={setShowModal}
              triggerFetch={triggerFetch}
            />
          )}
          {showUpdateForm && (
            <UpdateEvent
              targetID={targetID}
              setShowUpdateForm={setShowUpdateForm}
              setShowModal={setShowModal}
              triggerFetch={triggerFetch}
            />
          )}

          {showDeleteSection && (
            <DeleteItem
              api={EVENTS_API}
              targetID={targetID}
              employee={employee}
              setShowModal={setShowModal}
              setShowDeleteSection={setShowDeleteSection}
              hasName
              triggerFetch={triggerFetch}
            />
          )}
          {showApprove && (
            <ApproveItem
              api={EVENTS_API + "/ticket/"}
              targetID={targetID}
              employee={employee}
              setShowModal={setShowModal}
              setShowDeleteSection={setShowDeleteSection}
              hasName
              triggerFetch={triggerFetch}
            />
          )}
          {showArchiveSection && (
            <ArchiveItem
              api={ALL_EVENTS_API + "archive/"}
              targetID={targetID}
              employee={employee}
              setShowModal={setShowModal}
              setShowArchiveSection={setShowArchiveSection}
              hasName
              triggerFetch={triggerFetch}
              isArchive={archive}
            />
          )}
        </Modal>
      )}

      {showModal && showViewSection && (
        <Modal
          view
          handleClose={handleClose}
          modalHeading={showViewSection && `View Event Item`}
        >
          <ViewEvent targetID={targetID} />
        </Modal>
      )}
    </>
  );
}

export default EventsContent;
